export const navItems = [
  {
    name: "About"
  },
  {
    name: "Skills"
  },
  {
    name: "Projects"
  },
  {
    name: "Contact"
  }
];
